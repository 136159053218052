import Request from '@/utils/request'

export function getProducerListApi (data) {
  return Request({
    url: '/product/producer',
    params: data
  })
}

export function saveProducerApi (data) {
  return Request({
    url: '/product/producer/save',
    method: 'post',
    data
  })
}
